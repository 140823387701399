<template>
    <div class="loginContainer">
        <div class="loginContentView">
            <div class="loginTitle">LF视频上传</div>
            <div class="loginInputView">
                <el-input type="text" class="loginInput" placeholder="手机号" v-model="form.mobile"></el-input>
            </div>
            <div class="loginInputView">
                <el-input type="password" class="loginInput" placeholder="验证码" v-model="form.pin" @keydown.enter.native="verifyLoginPin" style="width:220px"></el-input>
                <el-button type="primary" @click="createLoginPin" :disabled="!enableSendMsg">{{countDownTimes}}</el-button>
            </div>
            <div class="loginBtn" @click="verifyLoginPin">登录</div>
        </div>
    </div>
</template>

<script>
import {setUserId} from '../../utils/store'
import {createLoginPin,verifyLoginPin} from '../../api/index'
export default {
    name: 'Login',
    data() {
        return {
            form: {
                mobile: '',
                pin: '',
            },
            countDownTimes:'发送验证码',
            countDownThread:'',
            enableSendMsg:true
        }
    },
    methods: {
        startCountDown() {
            let totalTime = 60
            this.countDownThread = window.setInterval(()=> {
                let remainTime = totalTime
                this.countDownTimes = remainTime + '秒后重发'
                totalTime--
                if (totalTime <= 0) {
                    this.enableSendMsg = true
                    this.countDownTimes = '发送验证码'
                    window.clearInterval(this.countDownThread)
                }
            }, 1000)
        },
        createLoginPin(){
            if(!this.form.mobile || this.form.mobile.length!==11){
                this.$message.warning('手机号格式不对')
                return
            }
            createLoginPin(this.form.mobile).then(res=>{
                if(res.data.Code==='OK'){
                    this.$message.success('短信发送成功')
                    this.enableSendMsg = false
                    this.startCountDown()
                }
            })
        },
        verifyLoginPin(){
            verifyLoginPin(this.form.mobile,this.form.pin).then(res=>{
                if(res.data.code===0){
                    setUserId(res.data.data._id.$id)
                    this.$message.success('登录成功')
                    this.$router.push('/upload')
                }else{
                    this.$message.warning(res.data.msg)
                }
            })
        }
    }
}
</script>

<style>
.loginContainer{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loginBackImg{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
}
.loginContentView{
    width: 580px;
    height: 380px;
    background: rgba(255,255,255,0.81);
    box-shadow: 2px 6px 7px 2px #409EFF;
    border-radius: 28px;
}
.loginTitle{
    text-align: center;
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #409EFF;
    margin-top:40px;
}
.loginIcon{
    width: 30px;
}
.loginInputView{
    width: 340px;
    margin: 0 auto;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.loginBtn{
    width: 340px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    margin: 0 auto;
    margin-top:40px;
    background: #409EFF;
    border-radius: 10px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #FFFFFF;
    font-size: 20px;
    cursor: pointer;
}
.loginBtn:hover{
    opacity: 0.8;
}
.loginInput{
    width: 440px;
}


</style>
